<template>
  <div class="cool-button">
    <slot />
  </div>
</template>

<style scoped>
.cool-button {
  padding: 15px 30px;
  color: var(--button-hover-color);
  align-self: center;
  background: var(--button-color);
  font-weight: bold;
  border-radius: 50px;
  transition: background 0.5s ease-in-out, font-size 0.8s ease-in-out,
    color 1.5s ease-in-out;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}
.cool-button:hover {
  font-size: 18px;
  background: var(--button-hover-color);
  color: var(--button-color);
}

/* Pseudo-elements for the gradient background */
.cool-button::after,
.cool-button::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;

  background-image: conic-gradient(
    from var(--angle),
    #3498db,
    #00a6ff,
    #8ec6ff,
    #3498db,
    #0072ff,
    #3498db
  );
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: -1;
  padding: 3px;
  border-radius: 50px;
  transition: opacity 2s ease-in-out; /* Smooth transition */
  animation: 3s spin linear infinite;
  opacity: 0.1;
}

/* Hover state: Make the pseudo-elements visible */
.cool-button:hover::after,
.cool-button:hover::before {
  opacity: 1;
}

/* Blur effect for the ::before element */
.cool-button::before {
  filter: blur(0.5rem);
  opacity: 0.2;
}
@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}
</style>
