<template>
  <footer class="footer">
    <p>&copy; 2024 HumanAIs. All Rights Reserved.</p>
    <!-- <p><a href="#cta">Contact Us</a> | <a href="#faq">FAQ</a></p> -->
  </footer>
</template>

<style scoped>
.footer {
  padding: 20px;
  background-color: #333;
  color: white;
  text-align: center;
}
.footer a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}
.footer a:hover {
  text-decoration: underline;
}
</style>
