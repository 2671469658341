<template>
  <section id="faq" class="faq">
    <h2 class="section-title">Frequently Asked Questions</h2>
    <div class="faq-container">
      <div
        v-for="(item, index) in faqItems"
        :key="index"
        :id="item.id"
        class="faq-item"
        @click="toggleFAQ(index)"
      >
        <h3 class="faq-question">
          {{ item.question }}
          <span class="arrow" :class="{ open: activeIndex === index }">▼</span>
        </h3>
        <p
          v-show="activeIndex === index"
          class="faq-answer"
          v-html="item.answer"
        ></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    // On initial page load with hash
    if (window.location.hash) {
      const faqId = window.location.hash.replace("#", "");
      this.openFAQFromLink(faqId);
    }

    // Listen to hash changes (e.g., clicking a link with #license)
    window.addEventListener("hashchange", () => {
      const faqId = window.location.hash.replace("#", "");
      this.openFAQFromLink(faqId);
    });
  },
  data() {
    return {
      activeIndex: null,
      faqItems: [
        {
          id: "how",
          question: "How does it work?",
          answer: `HumanAIs act as a smart wrapper
          around your LLM responses. It splits each response into natural,
          human-like chunks and adapts the flow in real-time based on user
          interaction and chat history. By continuously monitoring user
          behaviour, it dynamically decides whether to keep writing, slow down,
          or trigger new responses. Simulating real typing patterns based on the analysis of human interactions, each interaction feels more authentic.
        `,
        },
        {
          id: "why",
          question: "Why would I want my bot to feel like a human?",
          answer:
            "In some case, you might just want your bot to deliver the information as quickly and efficiently as possible, and that's fine. However, in other situations - like customer support, entertainment, or any scenario where empathy and engagement are key - a human-like experience can significantly enhance the interaction. <strong>Adding a human factor can create a more personal, relatable, and satisfying conversation, making users feel more connected to your service.</strong>",
        },

        {
          id: "what",
          question: "What solutions do you offer?",
          answer: `
            We offer two primary solutions to integrate our naturalistic chatting system <strong>HumanAIs</strong> into your platform:
            <ul>
              <li><strong>Compiled Binaries:</strong> A secure, easy-to-integrate version of our system without access to the source code. This is ideal for clients seeking a cost-effective solution with minimal customization.</li>
              <li><strong>Full Codebase License:</strong> For clients who require more flexibility, we offer full access to the codebase, allowing your development team to modify and extend the system as needed. Pricing for this option reflects the greater control and customization potential.</li>
              </ul>
              <strong>Both solutions are provided for a one-time fee, and the license for use is perpetual, meaning you can continue using the system without recurring costs.</strong><br>
             In both cases, we provide documentation, an installation guide, and a demo to help you integrate HumanAIs into your platform. If you need further assistance, we are happy to offer consulting services to tailor the integration of our system to your specific needs, ensuring smooth implementation within your existing platform.
            <br>All options are provided under a <strong>non-exclusive usage license</strong>. For more details, refer to the FAQ titled <a href="#license" style='color: var(--accent-color)'>"What's the license?"</a>.
          `,
        },
        {
          id: "license",

          question: "What's the license?",
          answer: `
            When you purchase our naturalistic chatting system <strong>HumanAIs</strong>, you receive a <strong>non-exclusive usage license</strong>. This means that while you're free to integrate and use the system in your own platform, other clients can also purchase and use the same technology. Key details of the license include:
            <ul>
              <li><strong>Non-Exclusivity:</strong> You do not have exclusive rights to the system. Other companies may also license and integrate it into their products.</li>
              <li><strong>Usage Rights:</strong> You are granted the right to use, integrate, and customize the system for your internal purposes. You may not resell or redistribute the system without explicit permission.</li>
              <li><strong>No Derivative Works:</strong> You are not allowed to modify the system in such a way that it creates a derivative product for resale or distribution.</li>

            </ul>
            We are also flexible and open to discussing custom licensing options, including exclusive or tailored solutions, depending on your specific business needs. Please reach out to us to explore these possibilities.
          `,
        },

        {
          id: "help",

          question: "Can you help us integrate your code into our platform?",
          answer:
            "Yes, we offer custom integration services for an additional fee. Feel free to <a href='#cta' style='color: var(--accent-color)'>reach out</a> to know more!",
        },
        {
          id: "whatLLM",

          question: "What kinds of LLMs can your system work with?",
          answer:
            "Our system is designed to work with any LLM. Whether you're using GPT-based models, Llama, or any other large language model, our code will make your chatbot more human-like. The use-case demos shown above, for example, are powered by Llama 3.1.",
        },
        {
          id: "different",

          question:
            "How is this different from just improving the LLM itself or adjusting the system prompt?",
          answer:
            "Our solution isn't about altering the LLM's underlying model or simply playing around with the system prompt; it's about how the LLM's responses are delivered. By separating prompt manipulation from the delivery of truly human-like interactions, you can tailor your bot to any use case while maintaining a natural conversational flow. You won't achieve this level of realism by just adjusting the system prompt - trust us, we've tried!",
        },
        {
          id: "personalize",
          question: "Is your chatting system customizable?",
          answer:
            "Yes, you will have access to various parameters that control the agent's overall chattiness, the speed of the interaction, how often it will try to re-engage in the conversation, and much more.",
        },
        {
          id: "modify",
          question:
            "What if we want to modify the code to fit our specific needs?",
          answer: `
            You are allowed to modify and adapt the code to fit your platform's specific requirements, provided you have opted for the full codebase license. While you own the implementation within your platform, we retain the intellectual property rights to the core system. As per the terms of the license, you are prohibited from reselling or redistributing the system, and modifications cannot be used to create derivative products for commercial purposes. If you need further customization or help with integration, we also offer consulting services to assist with these needs.
          `,
        },
        {
          id: "saas",
          question: "Why don't you provide a SaaS platform?",
          answer: `
           We believe in offering flexibility to our clients, which is why we provide a codebase that you can integrate directly into your own platform. Additionally, integrating our system with the various APIs and platforms our clients use—such as large language models (LLMs)—can involve unique configurations. A SaaS platform could introduce challenges with security and customization that are better handled within your own infrastructure.

           By providing the source code or compiled binaries, we offer a solution that fits seamlessly into your existing setup, while still protecting our intellectual property. We are always open to discussing custom solutions to suit your business needs.
         `,
        },
        {
          id: "privacy",
          question: "Does your system require data to be sent over a network?",
          answer:
            "No, with both <a href='#what' style='color: var(--accent-color)'>solutions</a> we offer, the system is fully installed on your platform, so data processing happens locally.",
        },
      ],
    };
  },
  methods: {
    toggleFAQ(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
        // Clear the hash when the FAQ is closed
        window.history.pushState(null, null, " ");
      } else {
        this.activeIndex = index;
        // Set the hash to the id of the selected FAQ item
        const faqId = this.faqItems[index].id;
        window.history.pushState(null, null, `#${faqId}`);
      }
    },
    openFAQFromLink(faqId) {
      const faqIndex = this.faqItems.findIndex((item) => item.id === faqId);
      if (faqIndex !== -1) {
        this.toggleFAQ(faqIndex); // Open the FAQ
        document.getElementById(faqId).scrollIntoView({ behavior: "smooth" }); // Scroll to the FAQ
      }
    },
  },
};
</script>

<style scoped>
#faq {
  padding-top: 8dvh;
  margin-top: -8dvh;
}
.faq {
  padding: 50px;
  background-color: #f9f9f9;
  text-align: center;
}
.faq-container {
  padding-top: 5px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}
.faq-item {
  /* margin-bottom: 20px; */
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  /* padding-bottom: 10px; */
}
.faq-question {
  font-size: 1.1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq-answer {
  font-size: 1rem;
  color: #666;
  transition: all 0.3s ease;
}
.arrow {
  transition: transform 0.3s ease;
}
.arrow.open {
  transform: rotate(180deg);
}
</style>
