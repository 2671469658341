<template>
  <section id="try-it" class="try-it">
    <h2 class="section-title">Try It Now Live!</h2>

    <div
      class="try-it-container"
      ref="scrollContainer"
      @mousedown="startDrag"
      @mousemove="onDrag"
      @mouseup="stopDrag"
      @mouseleave="stopDrag"
    >
      <!-- Customer Service Bot Card -->
      <div data-aos="flip-left" class="use-case-box-parent">
        <div class="use-case-box">
          <div class="icon">💼</div>
          <h3 class="use-case-title">Customer Service Bot</h3>
          <p class="use-case-description">
            Offer customer support that feels personal, like talking to a real
            agent.
          </p>
          <CoolButton @click="startBot('customer_service')">
            Load Agent</CoolButton
          >
        </div>
      </div>

      <!-- AI Companion Card -->
      <div
        data-aos="flip-left"
        data-aos-delay="300"
        class="use-case-box-parent"
      >
        <div class="use-case-box">
          <div class="icon">🤖</div>
          <h3 class="use-case-title">AI Companion</h3>
          <p class="use-case-description">
            Create meaningful, human-like conversations for companionship.
          </p>
          <CoolButton @click="startBot('ai_companion')"> Load Agent</CoolButton>
        </div>
      </div>
      <!-- Social Media Bot Card -->
      <div
        data-aos="flip-left"
        data-aos-delay="600"
        class="use-case-box-parent"
      >
        <div class="use-case-box">
          <div class="icon">🌐</div>
          <h3 class="use-case-title">Social Media Bots</h3>
          <p class="use-case-description">
            Interact with users naturally, improving engagement and conversation
            quality.
          </p>
          <CoolButton @click="startBot('social_media')"> Load Agent</CoolButton>
        </div>
      </div>
      <!-- Educational Tutor Card -->
      <div
        data-aos="flip-left"
        data-aos-delay="900"
        class="use-case-box-parent"
      >
        <div class="use-case-box">
          <div class="icon">📚</div>
          <h3 class="use-case-title">Educational Tutor</h3>
          <p class="use-case-description">
            Provide a learning experience that feels more like a real tutor
            guiding the student.
          </p>
          <CoolButton @click="startBot('educational_tutor')">
            Load Agent</CoolButton
          >
        </div>
      </div>
      <!-- Mental Health Support Card -->
      <div
        data-aos="flip-left"
        data-aos-delay="1200"
        class="use-case-box-parent"
      >
        <div class="use-case-box">
          <div class="icon">💙</div>
          <h3 class="use-case-title">Mental Health Support</h3>
          <p class="use-case-description">
            Empathetic, human-like conversations for comfort and understanding.
          </p>
          <CoolButton @click="startBot('mental_health')">
            Load Agent</CoolButton
          >
        </div>
      </div>
      <div
        data-aos="flip-left"
        data-aos-delay="1500"
        class="use-case-box-parent"
      >
        <div class="use-case-box" style="margin-right: 20px">
          <i class="icon">🎉</i>
          <h3 class="use-case-title">Whatever You Want!</h3>
          <p class="use-case-description">
            Our system is flexible and can adapt to your unique needs. Let your
            imagination run wild!
          </p>
        </div>
      </div>
    </div>
    <ChatSection ref="chatbot" />
    <CoolPanel class="separator" v-if="!botStarted"
      >Select Your Agent
    </CoolPanel>
  </section>
</template>

<script>
import CoolPanel from "./CoolPanel.vue";
import CoolButton from "./CoolButton.vue";
import ChatSection from "@/components/ChatSection.vue";
// import IsTyping from "./IsTyping.vue";
export default {
  components: {
    ChatSection,
    CoolButton,
    CoolPanel,
    // IsTyping,
  },
  data() {
    return {
      isDragging: false,
      isMouseDown: false,
      startX: 0,
      scrollLeft: 0,
      velocity: 0, // Track the drag velocity
      momentumID: null, // Used for canceling momentum scrolling
      lastMouseX: 0, // To calculate the velocity
      lastTimestamp: 0, // Timestamp to measure drag speed
      dragThreshold: 10, // Threshold before drag activates
      botStarted: false,
    };
  },
  methods: {
    startBot(role) {
      this.$refs.chatbot.startBot(role);
      this.botStarted = true;
    },
    startDrag(e) {
      this.isMouseDown = true;
      this.startX = e.pageX; // Store the starting X position of the drag
      this.scrollLeft = this.$refs.scrollContainer.scrollLeft; // Store the initial scroll position
      this.lastMouseX = e.pageX; // Track the mouse position for velocity
      this.lastTimestamp = Date.now(); // Track time for velocity calculation
      this.velocity = 0; // Reset velocity when drag starts
      clearInterval(this.momentumID); // Stop any ongoing momentum
    },

    onDrag(e) {
      if (!this.isMouseDown) return;

      const x = e.pageX; // Get current mouse X position
      const move = x - this.startX; // Calculate how much the mouse has moved

      // Set the dragging flag and prevent default action
      if (Math.abs(move) > this.dragThreshold) {
        this.isDragging = true;
        e.preventDefault();

        // Apply scroll based on the movement of the mouse
        this.$refs.scrollContainer.scrollLeft = this.scrollLeft - move;

        // Calculate velocity based on mouse movement
        const now = Date.now();
        const deltaX = e.pageX - this.lastMouseX; // Change in mouse X
        const deltaTime = now - this.lastTimestamp; // Change in time

        // Update velocity (distance/time)
        this.velocity = deltaX / deltaTime;

        // Update last mouse position and timestamp
        this.lastMouseX = e.pageX;
        this.lastTimestamp = now;
      }
    },

    stopDrag(e) {
      this.isMouseDown = false;

      // If dragging was happening, apply momentum after drag stops
      if (this.isDragging) {
        this.applyMomentum();
      }
      if (!this.isDragging) {
        this.centerCard(e.target);
      }
      this.isDragging = false;
    },

    applyMomentum() {
      const friction = 0.95; // Friction to reduce velocity over time
      const step = () => {
        // Only continue if there's still some velocity left
        if (Math.abs(this.velocity) > 0.1) {
          // Apply the velocity to the scroll position
          this.$refs.scrollContainer.scrollLeft -= this.velocity * 20;

          // Reduce the velocity by applying friction
          this.velocity *= friction;

          // Request the next animation frame to keep the momentum going
          this.momentumID = requestAnimationFrame(step);
        } else {
          // Stop the momentum if velocity is negligible
          cancelAnimationFrame(this.momentumID);
        }
      };
      step(); // Start applying momentum
    },
    centerCard(clickedElement) {
      // Find the closest card (use-case-box) element to the clicked target
      const card = clickedElement.closest(".use-case-box");

      if (!card) return; // Return if no card is found

      // Calculate the scrollContainer width and the card's offset
      const container = this.$refs.scrollContainer;
      const containerWidth = container.clientWidth;
      const cardWidth = card.clientWidth;

      // Calculate the card's current position within the container
      const cardOffsetLeft = card.offsetLeft;

      // Calculate the new scroll position to center the card
      const scrollTo =
        cardOffsetLeft - (containerWidth / 2 - cardWidth / 2) - 50;

      // Smoothly scroll the container to the calculated position
      container.scrollTo({
        left: scrollTo,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
/* Use Cases Section */
.separator {
  flex-grow: 0.5;
  font-size: 1.2rem;
  padding: 1rem 0;
  align-self: center !important;
}
:deep(.cool-panel.class-0) {
  --border-radius: 50px 50px 50px 50px !important;
}

.try-it {
  padding: 8dvh 50px 50px;
  min-height: 100dvh;
  background-color: #f9f9f9;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 0;
  margin-bottom: 10dvh;
}

@media (max-width: 500px) {
  .try-it {
    padding: 8dvh 0px;
  }
}
.section-title {
  margin: 0;
  font-size: 2.5rem;
  color: var(--primary-color);
}

/* Scrollable Container */
.try-it-container {
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  white-space: nowrap;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  cursor: grab;
  user-select: none;
}

* {
  box-sizing: border-box;
}
.use-case-box-parent {
  z-index: 0;
  display: flex;
  align-items: center;
  text-align: center;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: visible;
  margin-bottom: 10px;
}
.use-case-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: clamp(230px, 25dvw, 380px);
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.use-case-box:hover {
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1), -10px -10px 10px white;
  transform: scale(1.05);
}

.icon {
  font-size: clamp(1rem, 10dvw, 2.5rem);
  color: var(--accent-color);
}

.use-case-title {
  font-size: clamp(1, 10dvw, 1.5rem);
  margin-bottom: 10px;
  margin-top: 0px;
  color: var(--primary-color);
}

.use-case-description {
  font-size: 1rem;
  color: var(--text-color);
}

/* Hide scrollbars on Webkit browsers */
.try-it-container::-webkit-scrollbar {
  display: none;
  cursor: grab;
  user-select: none;
}

.try-it-container:active {
  cursor: grabbing;
}
</style>
