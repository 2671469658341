<template>
  <div id="hero" class="hero">
    <div class="hero-content">
      <h1 class="hero-title">
        <span class="humanize" ref="humanize">HumanAIs</span><br />Your Chatbot!
      </h1>
      <!-- <transition-group name="balloon" tag="div" class="dynamic-right"> -->
      <div class="chat">
        <CoolPanel
          :typeBaloon="0"
          v-for="(panel, index) in filteredPanels"
          :key="index"
          :type-baloon="panel.position == 'right' ? 0 : 1"
          :class="`hero-baloon  bal${index + 1}`"
          :content="panel.content === 'typing' ? '' : panel.content"
        >
          <is-typing v-if="panel.content === 'typing'" />
        </CoolPanel>
      </div>
    </div>
  </div>
</template>
<script>
import IsTyping from "./IsTyping.vue";
import CoolPanel from "@/components/CoolPanel.vue";
export default {
  computed: {
    filteredPanels() {
      return this.bal.filter((panel) => panel.content !== "");
    },
  },
  data() {
    return {
      bal: [
        { content: "", position: "right" },
        { content: "", position: "left" },
        { content: "", position: "right" },
        // Add more elements as needed
      ],
    };
  },
  components: {
    CoolPanel,
    IsTyping,
  },
  mounted() {
    setTimeout(() => this.startFilling(), 1000);
    const element = this.$refs.humanize;

    // Add the first animation
    element.classList.add("animate-colorShift");

    // Listen for the first animation's end and trigger the second one
    element.addEventListener("animationend", () => {
      element.classList.remove("animate-colorShift");
      element.classList.add("animate-pulsate");
    });
  },
  methods: {
    startFilling() {
      const texts = [
        {
          content: "Introducing the most human-like chatting system!",
          typingTime: 3200,
        },
        {
          content:
            "Turn your old boring chatbot into a more engaging, realistic experience",
          typingTime: 1500,
        },
        { content: "<a href='#try-it'>Try it Now!</a>", typingTime: 1000 },
      ];
      let cumulativeDelay = 0;

      texts.forEach((textObj, index) => {
        // Total delay for the previous bubble to appear, plus the custom typing time
        setTimeout(() => {
          this.setTypingAndContent(index, textObj.content, textObj.typingTime);
        }, cumulativeDelay);

        // Add typing time and the display delay for the next bubble
        cumulativeDelay += textObj.typingTime + 1000; // 500ms delay between bubbles
      });
    },
    setTypingAndContent(index, text, typingTime) {
      // Set "typing" for the custom typing time
      this.bal[index].content = "typing";

      setTimeout(() => {
        // Set the actual content after the typing delay
        this.bal[index].content = text;
      }, typingTime);
    },
  },
};
</script>
<!-- #42b9ff -->
from './CoolButton.vue';
<style scoped>
:deep(.container) {
  max-width: 60%;
  min-width: 20%;
}

@media (max-width: 500px) {
  :deep(.container) {
    max-width: 80%;
  }
}
.chat {
  width: 35%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.humanize {
  font-weight: bold;
  background: linear-gradient(to bottom, #0050b1, #00aaff04, #ffff00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent; /* Fallback for non-WebKit browsers */
  display: inline-block;
  background-size: 100% 1000%;
  text-shadow: none;
  position: relative;
}
.animate-colorShift {
  animation: colorShift 4s ease-in-out 1 forwards;
}

.animate-pulsate {
  animation: pulsate 5s infinite;
  background-position: 50% 100%;
}

.hero {
  width: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  z-index: 1;
}
.hero-content {
  width: 100%;
  display: flex;
  gap: clamp(10px, 10dvw, 100px);
  justify-content: center;
  align-items: center;
}

.hero-title {
  font-size: clamp(1rem, 15dvw, 4rem);
  margin: 0;
  text-shadow: #0075ffb3 0px 0px 20px;
}
.hero-baloon {
  text-align: center;
  vertical-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 20px;
  position: relative;
}

@media (max-width: 900px) {
  #hero {
    padding-top: 8dvh;
  }
  .hero-content {
    flex-wrap: wrap;
    /* column-gap: 10px; */
  }
}

@media (max-width: 400px) {
  .chat {
    min-width: 300px;
  }
}
</style>
