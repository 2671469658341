<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  text-align: center;
  gap: clamp(0px, 0.5dvh, 5px);
  color: #2c3e50;
}
</style>
