<template>
  <div id="featured-in" class="container">
    <div>
      <h2 class="section-title">Featured In</h2>
      <p class="featured-description" data-aos="fade-up">
        Our Naturalistic Chatting System has been integrated into various
        products, offering seamless and human-like conversational experiences.
        <br />Below are two examples of our system in action, powering engaging
        interactions in real-world applications.
      </p>
    </div>
    <div class="site-container">
      <div class="site-box" data-aos="zoom-in-up" data-aos-delay="300">
        <a href="https://theAIpeeps.com" target="_blank">
          <img
            class="site-box-img aipeeps-img"
            src="https://theaipeeps.com/aipeeps.jpg"
            alt="theAIpeeps.com"
          />
        </a>
        <strong>The AI Peeps</strong>
        <div>An AI Companion website focused on realistic interactions</div>
      </div>
      <div class="site-box" data-aos="zoom-in-up" data-aos-delay="600">
        <a href="https://vaaal88.itch.io/trapped">
          <img
            class="site-box-img trapped-img"
            src="https://img.itch.zone/aW1nLzE2NzA4OTA0LnBuZw==/315x250%23c/na6eJL.png"
            alt="TRAPPED"
        /></a>
        <strong>TRAPPED</strong>
        <div class="trapped-descr">
          An unique game that melds elements of psychological horror and
          thriller within the confines of a terminal window
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#featured-in {
  padding-top: 8dvh;
  margin-top: -8dvh;
}

.container {
  min-height: 100dvh;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary-color);
  padding: 0 50px 50px 50px;
}
.featured-description {
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 20px;
  text-align: center;
}
.trapped-img {
  border-radius: 20px;
  height: 30dvh;
}
.trapped-descr {
  width: 30dvw;
  min-width: 300px;
}

.aipeeps-img {
  height: 30dvh;
  max-width: 90dvw;
  object-fit: cover;
  border-radius: 20px;
}
.site-container {
  gap: 50px;
  display: flex;
  justify-content: space-around;
  align-items: top;
  flex-wrap: wrap;
  padding: 0 0 100px 0px;
}

.section-title {
  margin: 0;
  font-size: 2.5rem;
  text-align: center;
  color: var(--primary-color);
}
strong {
  color: var(--primary-color);
}
div {
  color: var(--text-color);
}
.site-box {
  flex: 0.5;
  text-align: center;
  white-space: normal;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.site-box-img {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.site-box-img:hover {
  box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1), -10px -10px 10px white;
  transform: scale(1.01);
  /* box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1), -10px -10px 10px white; */
}

@media (max-width: 400px) {
  .featured-description {
    font-size: 1rem;
  }
}
</style>
