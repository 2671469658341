<template>
  <div class="main">
    <AppHeader />
    <div class="big-sect">
      <HeroSection />
      <ExplanationText />
    </div>
    <TryItOut />
    <FeaturedIn />
    <CTASection />
    <FAQSection />
    <AboutUs />
    <FooterSection />
  </div>
</template>

<script>
// Import your global styles
import "@/assets/style.css";
import AppHeader from "@/components/AppHeader.vue";
import HeroSection from "@/components/HeroSection.vue";
import ExplanationText from "@/components/ExplanationText.vue";
import AboutUs from "@/components/AboutUs.vue";
import TryItOut from "@/components/TryItOut.vue";
import FeaturedIn from "@/components/FeaturedIn.vue";
import CTASection from "@/components/CTASection.vue";
import FAQSection from "@/components/FAQSection.vue";
import FooterSection from "@/components/FooterSection.vue";
export default {
  components: {
    AppHeader,
    HeroSection,
    ExplanationText,
    TryItOut,
    CTASection,
    FAQSection,
    FooterSection,
    FeaturedIn,
    AboutUs,
  },
};
</script>
<style scoped>
.big-sect {
  background: linear-gradient(to bottom, #0072ff 8%, #00c6ff 40%, #f9f9f9 98%);
}
.main {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
}
</style>
