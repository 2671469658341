<template>
  <section data-aos="fade-up" id="about-us" class="about-section">
    <h2 class="section-title">About Us</h2>
    <div class="about-content">
      <p>
        We are a small team focused on creating AI-driven experiences that feel
        natural, intuitive, and engaging. Led by
        <a
          href="https://www.linkedin.com/in/valerio-biscione-6233968b"
          target="_blank"
          >Valerio Biscione</a
        >, our expertise in both Psychology and Machine Learning enables us to
        deliver innovative, human-like chatbots tailored to your platform's
        needs.
      </p>
      <p>
        Whether you're looking to enhance customer service, create AI
        companions, or improve user engagement, we are here to help you
        integrate powerful, naturalistic conversations into your platform. Our
        system is designed to be adaptable and scalable, ensuring that your
        users have a seamless experience.
      </p>
      <p>Join us as we redefine AI conversations for the future.</p>
      <p>Contact us to redefine the way your users engage with your chatbot!</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style scoped>
.about-section {
  padding: 50px;
  background-color: var(--background-color);
  color: var(--primary-color);
  text-align: left;
}
#about-us {
  padding-top: 8dvh;
  margin-top: -8dvh;
}

a:hover {
  color: var(--accent-color2); /* Link hover color */
  text-decoration: underline;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-content p {
  margin-bottom: 20px;
  line-height: 1.6;
}
</style>
