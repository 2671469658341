<template>
  <div class="chatSection" v-if="websocket">
    <div class="chat-proper">
      <div class="chatbox" ref="chatWindow">
        <div
          class="message"
          :class="message.sender_type === 'AI' ? 'AI' : 'user'"
          v-html="message.content"
          v-for="(message, index) in messages"
          :key="index"
        ></div>
        <div class="message AI" v-if="aiIsTyping">
          <is-typing />
        </div>
        <div v-if="messages.length == 0 && !aiIsTyping"><i>Loading...</i></div>
      </div>
      <!-- <div class="infoField" v-html="infoField"></div> -->
      <div class="inputField">
        <div
          @click="togglePause"
          class="button pauseButton"
          :class="chatPaused ? 'paused' : ''"
        >
          Pause&nbsp;<i
            class="fas"
            :class="chatPaused === true ? 'fa-play' : 'fa-pause'"
          ></i>
        </div>
        <input
          @keydown="inputKeyDown"
          @keyup.enter="sendMessage"
          v-model="typedMessage"
          class="messageInput"
          placeholder="Type here..."
        />
        <div @click="sendMessage" class="button sendButton">
          Send&nbsp;<i class="fas fa-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IsTyping from "./IsTyping.vue";
export default {
  components: {
    IsTyping,
  },
  data() {
    return {
      typedMessage: "",
      infoField: "No Agent Loaded",
      chatPaused: false,
      websocket: null,
      aiIsTyping: false,
      userIsTyping: false,
      doneTypingInterval: 3500,
      typingTimer: null,
      selectedOption: "option1",
      messages: [],
    };
  },

  beforeUnmount() {
    if (this.websocket !== null) {
      this.websocket.close();
    }
  },
  methods: {
    togglePause() {
      this.chatPaused = !this.chatPaused;
      if (this.chatPaused) {
        this.websocket.send(JSON.stringify({ type: "pause_chat" }));
        this.infoField = "Chat Paused";
      } else {
        this.websocket.send(JSON.stringify({ type: "activate_chat" }));
        this.infoField = "";
      }
    },
    startBot(role) {
      this.aiIsTyping = false;

      this.messages = [];
      //   const ws_scheme = window.location.protocol === "https:" ? "wss" : "ws";
      if (this.websocket !== null) {
        this.websocket.close();
      }
      const url = `${process.env.VUE_APP_WEBSOCKET_URL}/000/${role}`;
      console.log(url);
      this.websocket = new WebSocket(url);
      this.websocket.onmessage = this.handleWebSocketMessage;
    },
    handleWebSocketMessage(event) {
      const data = JSON.parse(event.data);
      if (data.type) {
        switch (data.type) {
          case "chat_message":
            this.addChatMessage(data.msg);
            this.scrollToBottom();

            break;
          case "typing_indicator_on":
            this.aiIsTyping = true;
            this.scrollToBottom();
            break;
          case "typing_indicator_off":
            setTimeout(() => {
              this.aiIsTyping = false;
            }, 500);
            break;
        }
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatWindow = this.$refs.chatWindow;
        if (chatWindow) {
          chatWindow.scrollTop = chatWindow.scrollHeight;
        }
      });
    },
    addChatMessage(message) {
      if (message.sender_type == "AI") {
        this.aiIsTyping = false;
      }

      this.messages.push(message);
    },
    sendUserTyping() {
      if (!this.user_is_typing) {
        this.user_is_typing = true;
        this.websocket.send(JSON.stringify({ type: "user_started_typing" }));
      }
      if (this.user_is_typing) {
        this.typingTimer = setTimeout(() => {
          this.sendUserStoppedTyping();
        }, this.doneTypingInterval);
      }
    },

    sendUserStoppedTyping() {
      if (this.user_is_typing) {
        this.user_is_typing = false;
        this.websocket.send(JSON.stringify({ type: "user_stopped_typing" }));
      }
    },
    inputKeyDown() {
      clearTimeout(this.typingTimer);
      this.sendUserTyping();
    },
    sendMessage() {
      if (this.chatPaused) return;
      if (this.typedMessage !== "") {
        this.sendUserStoppedTyping();
        const messageObj = {
          type: "user_send_text",
          msg: this.typedMessage,
        };
        this.websocket.send(JSON.stringify(messageObj));
        this.typedMessage = "";
      }
    },
    handleDropdownChange() {
      this.websocket.send(
        JSON.stringify({
          type: "dropdown_selected",
          value: this.selectedOption,
        })
      );
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.chatSection {
  --border-radius: 10px;
  position: relative;
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  margin: 20px;
}

.chat-proper {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  max-width: 500px;
  /* height: 300px; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  /* border-radius: var(--border-radius); */
  background-color: #f9f9f9;
}

.section-title {
  font-size: 2.5rem;
  padding: 0px;
  margin: 5px;
  /* margin-bottom: 20px; */
}

.chatbox {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  /* min-width: 300px; */
  padding: 20px;
  gap: 8px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: visible;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  flex: 1;
  overflow-y: scroll;
  scrollbar-width: none;
  max-height: 350px;
  -ms-overflow-style: none;
}
.chatbox::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.message {
  padding: 5px 15px;
  border-radius: var(--border-radius);
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-word;
}

.message.AI {
  background-color: #d1e7ff;
  text-align: left;
  align-self: flex-start;
  border-bottom-left-radius: 0;
}

.message.user {
  background-color: #ffa72661;
  text-align: left;
  align-self: flex-end;
  text-align: right;
  border-bottom-right-radius: 0;
}

.inputField {
  display: flex;
  gap: 10px;
  border-radius: var(--border-radius);
  /* background-color: #ddd; */
}

.infoField {
  display: flex;
  padding: 10px;
  /* height: 20px; */
  background-color: rgba(236, 234, 234, 0.89);
  border-radius: var(--border-radius);
  font-size: 16px;
}

input {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  font-size: 16px;
}

.button {
  padding: 10px;
  background-color: #3498db;
  border: none;
  color: white;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease, font-weight 1s ease;
}

.button:hover {
  background-color: #0037ff;
  transform: scale(1.05);
}

.agentId {
  flex-shrink: 1;
  width: 50px;
}

.btnContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.controlContainer {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.pauseButton,
.sendButton {
  white-space: nowrap;
  background-color: #0072ff;
}

.pauseButton.paused {
  background-color: #ffcc00;
}
@media (max-width: 400px) {
  input {
    max-width: 120px;
  }
}
</style>
