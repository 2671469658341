<template>
  <section class="cta" id="cta">
    <div class="cta-content">
      <h2 class="cta-title">
        <span class="text-human">HumanAIs</span><br />Your Chatbot Today!
      </h2>
      <div class="cta-subtitle">
        Give your users the most natural, human-like experience!
        <br /><strong class="text-human"
          >Reach out for a free consultation and quote!</strong
        >
      </div>
      <form @submit.prevent="submitForm" class="form">
        <div class="name-email">
          <div class="form-group">
            <label for="name">Name:</label>
            <input
              v-model="formData.name"
              type="text"
              id="name"
              placeholder="Your Name"
              required
            />
          </div>

          <div class="form-group">
            <label for="email">Email:</label>
            <input
              v-model="formData.email"
              type="email"
              id="email"
              placeholder="Your Email"
              required
            />
          </div>
        </div>

        <div class="form-group form-message">
          <label for="message">Message:</label>
          <textarea
            class="main-message"
            v-model="formData.message"
            id="message"
            placeholder="Your Message"
            required
          ></textarea>
        </div>

        <div class="form-group submit">
          <CoolButton @click="submitForm" type="submit" :disabled="loading">
            {{ loading ? "Sending..." : "Send Message" }}
          </CoolButton>
        </div>

        <div v-if="responseMessage" class="response-message">
          {{ responseMessage }}
        </div>
      </form>
    </div>
  </section>
</template>
<script>
import CoolButton from "./CoolButton.vue";
export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      loading: false,
      responseMessage: "",
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      this.responseMessage = "";

      try {
        let url = `${process.env.VUE_APP_URL}/send-email`;
        console.log(url);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.formData),
        });

        if (response.ok) {
          this.responseMessage = "Your message has been sent successfully!";
          this.formData = { name: "", email: "", message: "" }; // Reset form
        } else {
          this.responseMessage =
            "There was an error sending your message. Please try again.";
        }
      } catch (error) {
        this.responseMessage = "An error occurred. Please try again.";
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    CoolButton,
  },
};
</script>
<style scoped>
.submit {
  align-self: center;
}
.name-email {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px 40px;
  align-items: center;
  justify-content: center;
}
.cta {
  display: flex;
  min-height: 100dvh;
  background: linear-gradient(to bottom, #0072ff 10%, #00c6ff, #f9f9f9);
  color: white;
  text-align: center;
  /* height: 90dvh; */
  z-index: 0;
}
.form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  padding: 0 10px;
}
#cta {
  padding-top: 8dvh;
  margin-top: -8dvh;
}
.cta-content {
  flex-grow: 1;
  max-width: 600px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  z-index: 0;
}
.form-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
}
.main-message {
  flex-grow: 1;
  width: 100%;
}
.cta-title {
  font-size: clamp(1rem, 15dvw, 3rem);
  text-shadow: #0075ffb3 0px 0px 20px;
  margin: 0;
}

.cta-subtitle {
  font-size: 1.2rem;
  margin: 0;
}

.cta-button {
  padding: 15px 30px;
  background-color: white;
  color: var(--button-color);
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #f1f1f1;
}

.text-human {
  animation: pulsate 5s infinite;

  background: #ffff00;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: #ffcc00 0px 0px 50px;
}
* {
  box-sizing: border-box;
}
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input,
textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
}

* {
  box-sizing: border-box;
}

button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.response-message {
  margin-top: 20px;
  font-size: 16px;
  color: #2c3e50;
}
</style>
