<template>
  <header class="header">
    <div class="title">
      <a href="#hero" class="home">
        <div class="text-human">HumanAIs</div>
      </a>
    </div>

    <nav class="navbar">
      <ul class="nav-links">
        <li><a href="#try-it">Try It Out!</a></li>
        <li><a href="#featured-in">Featured In</a></li>
        <li><a href="#faq">FAQ</a></li>
        <li><a href="#about-us">About Us</a></li>
      </ul>
    </nav>

    <a href="#cta" class="button">
      <CoolButton> Contact Us!</CoolButton>
    </a>
  </header>
</template>

<script>
import CoolButton from "./CoolButton.vue";

export default {
  components: {
    CoolButton,
  },
  data() {
    return {
      isMenuOpen: false, // To handle the toggle state
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>
<style scoped>
/* Header Styles */
.title {
  display: flex;
  margin-left: 20px;
}
.home {
  text-decoration: none;
}
.button {
  font-size: 15px;
  margin-right: 20px;
  white-space: nowrap;
}

:deep(.cool-button) {
  padding: 10px 20px !important;
  margin-bottom: 5px;
  margin-top: 5px;
}

.text-human {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  background: #ffff00;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* animation: float 3s ease-in-out infinite; */
  text-shadow: #ffcc00 0px 0px 10px;
  transition: transform 0.8s ease-in-out;
}
.text-human:hover {
  transform: scale(1.1);
}
.header {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  top: 0;
  width: 100%;
  background: #0072ffcc;
  z-index: 100;
  backdrop-filter: blur(20px); /* Blur the background behind the element */
  -webkit-backdrop-filter: blur(10px); /* Safari support */

  /* box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1); */
}

.navbar {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  height: 8dvh;
  align-items: center;
}

.nav-links {
  list-style: none;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  display: flex;
  gap: clamp(10px, 5dvw, 100px);
}

.nav-links li a {
  color: white;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: var(--accent-color);
}

/* Section Styles */
.section {
  padding: 100px 20px;
  height: 100vh; /* Just for demonstration */
}

#hero {
  background-color: var(--background-color);
}

#features {
  background-color: var(--secondary-color);
  color: white;
}

#cta {
  background-color: var(--accent-color);
  color: white;
}

#faq {
  background-color: var(--discord-blue);
  color: white;
}
@keyframes float {
  0% {
    transform: translate(0, 0); /* Starting position */
  }
  25% {
    transform: translate(1px, -1px); /* Move right and up */
  }
  50% {
    transform: translate(0, 0px); /* Move up */
  }
  75% {
    transform: translate(-1px, -1px); /* Move left and slightly down */
  }
  100% {
    transform: translate(0, 0); /* Back to starting position */
  }
}

@media (max-width: 700px) {
  .navbar {
    display: none;
  }
}
</style>
