<template>
  <div data-aos="fade-up" class="explanation">
    <h1 class="explanation-h1">
      We specialize in making your chatbot feel more
      <span class="text-human">human</span>
    </h1>
    <div class="explanation-text">
      <div>
        Our proprietary code transforms any LLM, enhancing the way it delivers
        its responses to make them feel more natural, engaging, and human-like.
      </div>
      <div>
        We've studied human communication patterns and applied that knowledge to
        develop a system that mimics real human interactions.
      </div>
      <div>The result?</div>
      <div>
        <strong>
          A chatbot experience that feels less like talking to a machine and
          more like a genuine conversation with a person.</strong
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
.explanation {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary-color);
  padding: 0 50px;
}
.explanation-h1 {
  text-align: center;
}
.explanation-text {
  font-size: 1.2rem;
  color: var(--text-color);
  text-align: center;
}
@media (max-width: 400px) {
  .explanation-text {
    font-size: 1rem;
  }
}
.text-human {
  background: #ffff00;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: #ffcc00 0px 0px 50px;
}
</style>
