import { createApp } from "vue";
import { router } from "./router";
import App from "./App.vue";
import AOS from "aos";
import "aos/dist/aos.css";

window.addEventListener("load", () => {
  AOS.init();
});
const app = createApp(App);
app.use(router);
app.mount("#app");
