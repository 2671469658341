<template>
  <div class="container" :class="typeBaloon === 0 ? 'class-0' : 'class-1'">
    <div class="another-cont">
      <div class="cool-panel" :class="typeBaloon === 0 ? 'class-0' : 'class-1'">
        <div class="before" />
        <div class="after" />
        <div v-if="content !== ''" v-html="content"></div>
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
    typeBaloon: { type: Number, default: 0 },
  },
};
</script>
<style scoped>
.container {
  animation: float 3s ease-in-out infinite;
}

.another-cont {
  transition: transform 0.5s ease-in-out;
}
.another-cont:hover {
  transform: scale(1.03);
}
.cool-panel {
  --border-radius: 50px;
  padding: 15px 30px;
  border-radius: var(--border-radius);
  position: relative;
  transition: font-size 0.2s ease-in-out;
}
.container.class-0 {
  align-self: flex-end;
}
.container.class-1 {
  align-self: flex-start;
}
.cool-panel.class-0 {
  color: var(--button-hover-color);
  background: linear-gradient(to top right, #ffcc00, #8f7200);
  --border-radius: 50px 50px 0px 50px;
}
.cool-panel.class-1 {
  color: var(--button-hover-color);
  background: linear-gradient(to top right, #42b9ff, #0b478f);
  --border-radius: 50px 50px 50px 0px;
}
.after,
.before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: -1;
  padding: 3px;
  border-radius: var(--border-radius);
  transition: opacity 2s ease-in-out; /* Smooth transition */
  animation: 3s spin linear infinite;
  opacity: 1;
}

/* Blur effect for the ::before element */
.cool-panel.class-0 .before {
  filter: blur(1rem);
  opacity: 1;
}

.cool-panel.class-1 .before {
  filter: blur(2rem);
  opacity: 1;
}

.cool-panel.class-0 .after,
.cool-panel.class-0 .before {
  background-image: conic-gradient(
    from var(--angle),
    #ffcc00,
    #ff9900,
    #ff6600,
    #ffcc00,
    #ff8800,
    #ffcc00
  );
}

.cool-panel.class-1 .after,
.cool-panel.class-1 .before,
.cool-panel.class-0 .before {
  background-image: conic-gradient(
    from var(--angle),
    #3498db,
    #00a6ff,
    #8ec6ff,
    #3498db,
    #0072ff,
    #3498db
  );
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}
@keyframes float {
  0% {
    transform: translate(0, 0); /* Starting position */
  }
  25% {
    transform: translate(1px, -1px); /* Move right and up */
  }
  50% {
    transform: translate(0, 0px); /* Move up */
  }
  75% {
    transform: translate(-1px, -1px); /* Move left and slightly down */
  }
  100% {
    transform: translate(0, 0); /* Back to starting position */
  }
}
</style>
